import React, { useState } from 'react'
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { onAddOutreach, onTestOutreach } from "../../../../Redux/Actions/outreachAction";
import modalCloseIcon from '../../../../assets/images/modal-close.png';

const SettingModal = ({ show, handleClose, fetchOutreach, state, setState, buttonAdd }) => {
    const dispatch = useDispatch()
    const [tested, setTested] = useState(false)
    const [loader, setLoader] = useState({
        addLoader: false,
        testLoader: false
    })


    const handleTest = () => {
        let data = {
            username: state.username,
            from: state.email,
            password: state.password,
            port: state.port,
            ssl: state.ssl,
            host: state.url
        }
        setLoader({
            ...loader,
            testLoader: true
        })
        dispatch(onTestOutreach(data, setTested, loader, setLoader))
    }

    const handleAdd = () => {
        setLoader({
            ...loader,
            addLoader: true
        })
        if (buttonAdd === "Add") {
            dispatch(onAddOutreach("save-smtp", state, loader, setLoader, handleClose, fetchOutreach))
        } else {
            dispatch(onAddOutreach("update-smtp", state, loader, setLoader, handleClose, fetchOutreach))
        }


    }

    const handleChange = (e) => {
        const { name, value, checked } = e.target
        if (name === "ssl") {
            setState({
                ...state,
                ssl: checked ? "tls" : "ssl"
            })
        }
        else {
            setState({
                ...state,
                [name]: value
            })
        }
    }

    useEffect(() => {
        if (tested) {
            setTested(false)
        }
    }, [state])



    return (
        <Modal className="settingModal" show={show} centered>
            <Modal.Body style={{ background: "#100f27" }}>
                <div onClick={handleClose} className="vidClose text-end"><img src={modalCloseIcon} alt="" /></div>
                <div className="formSec">
                    <div className="inpField">

                        <div className="text-center mb-3">
                            <h4>Test/Add SMTP</h4>
                        </div>

                        <div className='outreachForm'>

                            <div className="mb-3 d-flex">
                                <div className="col-3 text-start">
                                    <label htmlFor="name" className="form-label ">Name :</label>

                                </div>
                                <div className="col-9  form-group form-group-alt mt-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        placeholder="Name"
                                        name='name'
                                        value={state.name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 d-flex">
                                <div className="col-3 text-start">
                                    <label htmlFor="email" className="form-label">From Email :</label>
                                </div>
                                <div className="col-9  form-group form-group-alt mt-0">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        placeholder="name@example.com"
                                        name='email'
                                        value={state.email}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 d-flex">
                                <div className="col-3 text-start">
                                    <label htmlFor="username" className="form-label">Username :</label>
                                </div>
                                <div className="col-9  form-group form-group-alt mt-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="username"
                                        placeholder="Username"
                                        name='username'
                                        value={state.username}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 d-flex">
                                <div className="col-3 text-start">
                                    <label htmlFor="password"
                                        className="form-label">Password :</label>
                                </div>
                                <div className="col-9  form-group form-group-alt mt-0">
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        placeholder="*******"
                                        name='password'
                                        value={state.password}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 d-flex">
                                <div className="col-3 text-start">
                                    <label htmlFor="url" className="form-label">Host URL :</label>
                                </div>
                                <div className="col-9  form-group form-group-alt mt-0">
                                    <input
                                        type="url"
                                        className="form-control"
                                        id="url"
                                        placeholder="http:/....."
                                        name='url'
                                        value={state.url}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>


                            <div className="mb-3 d-flex">
                                <div className="col-3 text-start">
                                    <label htmlFor="port" className="form-label">Port :</label>
                                </div>
                                <div className="col-9  form-group form-group-alt mt-0">
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="port"
                                        placeholder="Port Number"
                                        name='port'
                                        value={state.port}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 d-flex">
                                <div className="col-3 text-start">
                                    <label htmlFor="ssl" className="form-label">SSL/TLS :</label>
                                </div>

                                <div className="col-9  form-group form-group-alt mt-0 d-flex " >

                                    <div style={{ width: "50px" }}>
                                        <span style={{ fontSize: "17px" }}>SSL</span>
                                    </div>
                                    <div className="form-check form-switch me-2">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="ssl"
                                            style={{ display: "inherit" }}
                                            name="ssl"
                                            checked={state.ssl === "ssl" ? false : true}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div style={{ width: "80px" }} className="text-center ms-3">
                                        <span style={{ fontSize: "17px" }}>TLS</span>
                                    </div>
                                </div>
                            </div>
                            <div className="settingModalbutton text-end">
                                <button
                                    type='button'
                                    name='test'
                                    className='theme-btn'
                                    onClick={handleTest}
                                >
                                    {loader.testLoader ?
                                        <>
                                            <span>  Testing</span> <i className="fa fa-spinner fa-spin mr-2" />
                                        </> : <span>Test</span>
                                    }
                                </button>

                                <button
                                    type="button"
                                    className='ms-2 theme-btn'
                                    onClick={handleAdd}
                                    style={{ background: tested ? "" : "gray" }}
                                    disabled={!tested}
                                >
                                    {buttonAdd === "Add" ?
                                        loader.addLoader ? <> <span>Adding </span><i className="fa fa-spinner fa-spin mr-2" /> </> : <span>Add</span> :
                                        loader.addLoader ? <><span>Updating</span> <i className="fa fa-spinner fa-spin mr-2" /> </> : <span>Update</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SettingModal